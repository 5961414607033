<template>
    <div class="search-header-wrap">
        <div  class="saas_search">
            <el-input v-model="search"  placeholder="请输入关键字" @keydown.enter="searchFun('search',{})">
            <template #append>
                <el-button  @click="searchFun('search',{})"  >搜索</el-button>
                    
            </template>
            </el-input>
            <ul  class="search-tabs-wrap clearfix">
                <li v-for="item in tabList" :key="item" @click="()=>{search=item; searchFun('search',{})}" class="tab-item">
                    <span >{{item}}</span>
                </li>
            </ul>
        </div>
    </div>
    <div class="container ant-row">
        <div class="mb-10" style="min-height:50px;position: relative;">
            <span class="search-t">已筛选条件:</span>
            <div class="search-content">
                <el-tag v-for="item in whereList"  :key='item.code' class='m-5'  closable @close="handleCloseFun(item.code)">{{item.name}}</el-tag>
            <span class="filter_delete" v-if='whereList && whereList.length>0' @click="handleCloseFun('all')"><i class="iconfont ec-icon-delete"></i>清除全部</span>
            </div>
        </div>
        <div>
            
            <div class="mb-10">
                <span class="search-t">
                    报告类型：
                </span>
                <div class="search-content">
                    <ul>
                        <li v-for="item in styleList" :key="item.id" :class='{"active":whereList.find(t=>t.code=="style" && t.id==item.id)}' @click="searchFun('style',{id:item.id, name:item.name,value:item.id})">{{item.name}}</li>
                    </ul>
                </div>
            </div>
            <div v-if='whereList.find(t=>t.code=="style")?.id==2' class="mb-10">
                <span class="search-t">
                    地区：
                </span>
                <div class="search-content">
                    <ul class="clearfix">
                        <li v-for="item in regionList" :key="item.code" :class='{"active":whereList.find(t=>t.code=="regionCode" && t.id==item.code)}'  @click="searchFun('regionCode',{id:item.code,name:item.name,value:item.code.toString(),parent:item.parent})">{{item.name}}</li>
                    </ul>
                </div>
            </div>
            <div v-if='whereList.find(t=>t.code=="style")?.id==1' class="mb-10">
                <span class="search-t">
                    产业领域：
                </span>
                <div class="search-content">
                    <ul class="clearfix">
                        <li v-for="item in industryClassList.filter(t=>t.parent==0)" :key="item.id" :class='{"active":whereList.find(t=>t.code=="parentIndustryClassId" && t.id==item.id)}'  @click="searchFun('parentIndustryClassId',{id:item.id,name:item.name,value:item.id.toString(),parent:item.parent})">{{item.name}}</li>
                    </ul>
                    <div v-if="whereList.find(e=>e.code=='parentIndustryClassId')" style="border-top:1px solid #ccc;margin-top:10px;padding-top:10px;">
                        <ul>
                            <li v-for="item in industryClassList.filter(t=>t.parent==whereList.find(e=>e.code=='parentIndustryClassId')?.id)" :key="item.id" :class='{"active":whereList.find(t=>t.code=="industryClassId" && t.id==item.id)}'  @click="searchFun('industryClassId',{id:item.id,name:item.name,value:item.id.toString(),parent:item.parent})">{{item.name}}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="mb-10">
                <span class="search-t">
                    时间筛选：
                </span>
                <div class="search-content">
                    <ul>
                        <li v-for="item in timeList" :key="item.id" :class='{"active":whereList.find(t=>t.code=="date" && t.id==item.id)}'  @click="searchFun('date',{id:item.id, name:item.name,value:item.value})">{{item.name}}</li>
                    </ul>
                        <!-- <el-date-picker v-if='isShowCustomDate'
                            style="margin:5px;"
                            v-model="customDate"
                            size="small"
                            type="daterange"
                            start-placeholder="Start Date"
                            end-placeholder="End Date"
                            @change="changDateFun"
                        /> -->
                </div>
            </div>
            
        </div>
        
        <div class="order-list clearfix">
            <ul >
                <li v-for="item in orderList" :key="item.id" :class="{'active':item.isSel}" @click="changOrderFun(item.id)">
                    {{item.name}} 
                    <i class="iconfont" :class="{'ec-icon-caret-bottom':item.isOrderDesc,'ec-icon-caret-top':!item.isOrderDesc}"></i>
                </li>
            </ul>
            <span style="text-align: right;position: absolute;right: 10px;top: 15px;" >找到 <span class="txt-color">{{totalCount}}</span> 条相关结果</span>
        </div>
        <div>
            <div v-for="report in reportList" :key="report.id">
                <div style="display: flex; padding: 10px 0px;">
                    <div class="list-img">
                        <el-image  :src="report.pic" fit="fill" style="height:100%;width:100%;" @click.prevent="push(report.id)" />
                    </div>
                    <div style="height: 100px; padding: 0px 10px; position: relative;">
                        <div style="display: flex; justify-content: space-between; align-items: center;">
                            <a @click.prevent="push(report.id)" >
                                <div style="font-size:18px; font-weight:bold;">{{report.title}}</div>
                            </a>
                            <span :style="{'background':report.type==2?'rgba(200,115,218,1)':'rgba(100,208,155,1)'}" style="padding: 0 8px;color: #fff;line-height: 20px;font-size: 12px;border-bottom-right-radius: 15px;border-top-left-radius: 15px;height: 20px;margin-left: 10px;">{{report.type==2?'付费':'免费'}}</span>
                        </div>
                        <div style="margin-top: 5px; margin-bottom: 10px;">
                            <el-tag v-for="label in report.labelList" size='small' :key='label.labelId' class='m-5'>{{label.labelName}}</el-tag>
                        </div>
                        <div style="font-size: 14px;color: rgb(157, 158, 159);margin-top: 20px;position: absolute;bottom: 0px;">
                            <span><i class="iconfont ec-icon-time"></i>&nbsp;{{parseTime(new Date(report.date),'{y}-{m}-{d}')}}</span>
                            <el-divider direction="vertical" />
                            <span><i class="iconfont ec-icon-page"></i>&nbsp;{{report.pageCount}}</span>
                            <el-divider direction="vertical" />
                            <span >
                                {{report.industryClassName}}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="ant-divider-horizontal" role="separator"></div>
            </div>
            <el-pagination
                @current-change="handleCurrentChangeFun"
                :current-page="pageIndex"
                :page-size='pageSize'
                layout="prev,pager,next"
                :total="totalCount">
            </el-pagination>
        </div>

    </div>
</template>
<script>
import { ref } from 'vue'
import {getIndustryClassList,getReportList,getReportLabelList} from '@/http/reportApi'
import {parseTime} from '@/utils/index'
import {addUserLog,getUserRightFun,getCodeRegionList} from '@/http/basicsApi.js'
import { useRoute, useRouter } from 'vue-router'
export default {
    setup() {
        let route=useRoute();
        let router=useRouter();
        let search=ref();//关键字
        let tabList=ref(['钢铁','无人机','新能源汽车','信息科技','芯片','理财','人工智能'])
        let industryClassList=ref([])
        let styleList=ref([{id:1,name:'行业报告'},{id:2,name:'地区报告'}])
        let t=new Date();//当前时间
        var t_s = t.getTime();//转化为时间戳毫秒数

        let timeList=ref([{id:1,name:'一周内',value:parseTime(t.setTime(t_s - 1000 * 60 * 60 * 24*7),'{y}-{m}-{d}')+','+parseTime(t_s,'{y}-{m}-{d}')},{id:2,name:'一月内',value:parseTime(t.setTime(t_s - 1000 * 60 * 60 * 24*30),'{y}-{m}-{d}')+','+parseTime(t_s,'{y}-{m}-{d}')},{id:3,name:'一年内',value:parseTime(t.setTime(t_s - 1000 * 60 * 60 * 24*365),'{y}-{m}-{d}')+','+parseTime(t_s,'{y}-{m}-{d}')}])
        let orderList=ref([{id:1,name:'综合排序',filed:'',isOrderDesc:true,isSel:true},{id:2,name:'发布时间',filed:'date',isOrderDesc:true,isSel:false},{id:3,name:'阅读量',filed:'browse',isOrderDesc:true,isSel:false}])
        let whereList=ref([]);
        let reportList=ref([]);//报告列表
        let totalCount=ref(0);
        let pageIndex=ref(1);
        let pageSize=ref(10)
        //let isShowCustomDate=ref(false);//是否显示自定义的时间选择器
        //let customDate=ref('');//自定义时间
        //获取产业领域列表
        const getIndustryClassListFun=async()=>{
            let params={
                // parent:0,
                pageIndex:1,
                pageSize:1
            }
            let res= await getIndustryClassList(params);
            console.log(res);
            if(res.code==20000){
                industryClassList.value=res.data.dataList
            }
        }
        //获取地区列表
        let regionList=ref([]);
        const getRegionListFun= async ()=>{
            let params={
                parent:'0'
            }
            //获取下级地区列表
            let result=  await getCodeRegionList(params);
            //console.log(result);
            if(result.code==20000){
                regionList.value=result.data.filter(t=>['710000','810000','820000'].findIndex(el=>el==t.code)<0);
            }
        }
        //获取报告列表
        const getReportListFun=async(type)=>{//0:加载,1:重新查询，2：分页查询
            if(type==0 || type==1){
                pageIndex.value=1;
                totalCount.value=0;
            }
            let orderObj=orderList.value.find(t=>t.isSel);
            let params={
                search:search.value?search.value:'',
                pageIndex:pageIndex.value,
                pageSize:pageSize.value,
                orderFiled:orderObj.filed,
                orderType:orderObj.isOrderDesc?'desc':''
            }
            whereList.value.forEach(t=>{
                if(t.code=='date'){
                    params['startDate']=t.value.split(',')[0];
                    params['endDate']=t.value.split(',')[1];
                }
                else{
                    params[t.code]=t.value
                }
                
            })
            reportList.value=[];
            console.log(params);
            let res= await getReportList(params);
            console.log(res);
            if(res.code==20000){
                totalCount.value=res.data.totalCount;
                //获取标签列表
                if(totalCount.value>0){
                    let res_=await getReportLabelList({objIds:res.data.dataList.map(t=>t.id).join(','),pageIndex:1,pageSize:1});
                    //console.log(res_);
                    res.data.dataList.forEach(t=>{
                        t.labelList=res_.data.dataList?res_.data.dataList.filter(el=>el.reportId==t.id):[]
                    })
                    reportList.value=res.data.dataList;
                }
            }
            //记录用户操作日志
            addUserLog(route.name,route.fullPath,type==0?'加载':'查询',JSON.stringify(params));
        }
        //单击分页
        const handleCurrentChangeFun=(current)=>{
            pageIndex.value = current;
            //报告查询
            getReportListFun(2);
        }
        //搜索跳转
        const searchFun= async(code,item)=>{
            // if(code=='date' && item.id==4){//显示时间选择器
            //     let index=whereList.value.findIndex(t=>t.code== code);
            //     //console.log(index);
            //     isShowCustomDate.value=true;
            //     whereList.value.splice(index,1)
            //     return false
            // }
            if(code=='search'){
                whereList.value=[];
            }
            else if(whereList.value.find(t=>t.code==code && t.id==item.id)){//已选择
               return false;
            }
            if(code!='search'){
                let index=whereList.value.findIndex(t=>t.code== code);
                
                if(index>=0){
                    whereList.value[index].id=item.id;
                    whereList.value[index].name=item.name;
                    whereList.value[index].value=item.value;
                    whereList.value[index].parent=item?.parent;
                }
                else{
                    whereList.value.push({code:code,id:item.id,name:item.name,value:item.value,parent:item?.parent})
                }
                if(code=='style' && item.id==1){//选中行业报告，清空地区，
                    let index=whereList.value.findIndex(t=>t.code=='regionCode');
                    if(index>=0){
                        whereList.value.splice(index,1);
                    }
                }
                else if(code=='style' && item.id==2){//选中地区报告，清空产业分类，
                    let index=whereList.value.findIndex(t=>t.code=='industryClassId');
                    if(index>=0){
                        whereList.value.splice(index,1);
                    }
                    let pindex=whereList.value.findIndex(t=>t.code=='parentIndustryClassId');
                    if(pindex>=0){
                        whereList.value.splice(pindex,1);
                    }
                }
                else if(code=='parentIndustryClassId'){
                    let index=whereList.value.findIndex(t=>t.code=='industryClassId');
                    if(index>=0){
                        whereList.value.splice(index,1);
                    }
                }
            }
            console.log(code);
            console.log(item);
            console.log(whereList.value);
            //获取报告列表
            getReportListFun(1);
            
        }
        // //自定义时间
        // const changDateFun=(val)=>{
        //     //console.log(val);
        //     let index=whereList.value.findIndex(t=>t.code== 'date');
        //     if(val){
        //         if(index>=0){
        //             whereList.value[index].value=val;
        //         }
        //         else{
        //             whereList.value.push({code:'date',id:4,name:'自定义时间',value:parseTime(new Date(val[0]),'{y}-{m}-{d}')+','+parseTime(new Date(val[1]),'{y}-{m}-{d}')})
        //         }
        //     }
        //     else{
        //         whereList.value.splice(index,1)
        //     }
        //     //获取报告列表
        //     getReportListFun(0);
        // }
        //删除筛选条件
        const handleCloseFun=(code)=>{
            if(code=='all'){//删除所有条件
                whereList.value=[];
            }
            else{
                let index=whereList.value.findIndex(t=>t.code==code);
                if(index>=0){
                    whereList.value.splice(index,1);
                }
            }
            //获取报告列表
            getReportListFun(1);
        }
        //改变排序字段
        const changOrderFun=(id)=>{
            let selObjIndex=orderList.value.findIndex(t=>t.isSel);
            let changObjIndex=orderList.value.findIndex(t=>t.id==id);
            if(selObjIndex!=changObjIndex){
                orderList.value[selObjIndex].isSel=false;
                orderList.value[changObjIndex].isSel=true;
                //获取报告列表
                getReportListFun(1);
            }
        }
        //跳转详细页面
        const push=async(id)=>{
            let path='/report/info'
            if(await getUserRightFun('reportInfo',0)){
                router.push({path:path,query:{id:id}});
            }
        }
        getIndustryClassListFun();
        getRegionListFun();
        getReportListFun(0);
        return{
            search,
            tabList,
            regionList,
            getRegionListFun,
            industryClassList,
            styleList,
            timeList,
            orderList,
            whereList,
            //isShowCustomDate,
            //customDate,
            //changDateFun,
            handleCloseFun,
            changOrderFun,
            reportList,
            push,
            parseTime,
            totalCount,
            pageIndex,
            pageSize,
            handleCurrentChangeFun,
            searchFun
        }
    },
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
.search-header-wrap{
    padding:30px;
    padding-top: 80px;
    background: $themeColor;
}
.saas_search{
    width:50%;
    margin:auto;
    ::v-deep .el-input__inner{
        height: 50px;
    }
    ::v-deep .el-input-group__append{
        border:none;
    }
    button{
        height: 50px;
        background: rgba($themeColor,0.7);
        font-weight: 700;
        font-size: 16px;
        color: #fff;
        border-radius: 0;
        letter-spacing: 5px;
    }
}
.search-tabs-wrap{
    li{
        float: left;
        line-height: 30px;
        margin: 10px;
        background: rgba(255,255,255,0.1);
        padding: 0 10px;
        color: #fff;
        border-radius: 4px;
        cursor: pointer;
    }
}
.search-t{
    position:absolute;
    padding:10px;
    color:gray;
}
.search-content{
    padding-left: 90px;
    overflow: hidden;
    ::v-deep .el-button{
        padding: 5px 15px;
        min-height: 31px;
    }
    ul >li{
        float: left;
        padding:5px 15px;
        cursor: pointer;
        border:1px solid $bordercolor;
        margin: 5px;
        border-radius: 4px;
    }
    ul >li:hover{
       color: $specialtext-color;// $titcolor;
    }
    ul >li.active{
       color: $specialtext-color;// $titcolor;
       background-color:rgba($themeColor,0.1);
       border:1px solid rgba($themeColor,0.2);
    }
    .el-input{
        width: 400px;
    }
}

.order-list{
    background-color:$bordercolor;
    padding:10px;
    margin-bottom: 10px;
    position: relative;
    li{
        float: left;
        cursor: pointer;
        margin: 5px 10px;
        &.active{
            color: $specialtext-color;
        }
    }
}
.list-img{
    width:80px;
    min-width:80px;
    height:100px;
    margin-right:10px; 
    border: 1px solid $bordercolor;
    cursor:pointer;
    position: relative;
    box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.2);
}
.anticon {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.ant-divider {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0,0,0,.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum","tnum";
    background: $bordercolor;
}
.ant-divider, .ant-divider-vertical {
    position: relative;
    top: -0.06em;
    display: inline-block;
    width: 1px;
    height: 0.9em;
    margin: 0 8px;
    vertical-align: middle;
}

.text-for-four {
    -webkit-line-clamp: 4;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}
</style>